import { Directive, HostListener, Input, ElementRef, HostBinding, Version } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @Input() tooltip;
  $tooltip: Element;
  
  @HostBinding('class.tooltips') className = true;

  @HostListener('mouseover')
  onClick() {
    if(this.el.nativeElement.disabled) {
    this.$tooltip = document.createElement('span');
    this.$tooltip.textContent = this.tooltip;
    this.$tooltip.className = 'tooltip-text'
    this.el.nativeElement.appendChild(this.$tooltip);
    }
  }

  @HostListener('mouseleave')
  onLeave() {
    this.el.nativeElement.removeChild(this.$tooltip)
  }

  constructor(private el: ElementRef) { }

}