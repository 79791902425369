import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
declare var $: any; // Declare jQuery
@Injectable({
    providedIn: 'root',
})
export class ProgressService {
    connection: any;
    hubProxy: any;

    constructor() {
      this.startConnection();
    }
  
    startConnection() {
      if(this.connection == null){
        this.connection = $.hubConnection(environment.baseUri.substring(0, environment.baseUri.length - 4 )); // Update with your server URL
        this.hubProxy = this.connection.createHubProxy('ProgressHub');
        this.connection.start({transport : 'longPolling'})
          .done(() => {
          console.log('Connection started');
          
          })
          .fail((error: any) => console.log('Error while starting connection: ' + error));
      }
    }

    addChangeListenr(callback : (attachmentKey : number, status : number ) => void ) {
      if(this.hubProxy){
        this.hubProxy.on('showProgress', (attachmentKey: number, status: number) => {
            callback(attachmentKey, status);
            // Handle the incoming message as needed
          });
        }
    }
}