<app-header></app-header>
<div class="container">
    <div class="center-content">
            <h2 class="welcome-text">Welcome to FileCapture</h2>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
            <p style="color: white">Please do not Cancel or Refresh page</p>
        </ngx-spinner>
        <div class="row">
            <form [formGroup]="referenceForm" style="box-sizing: content-box;">
                    <input type="text" name="referenceNumber" maxlength="60" formControlName="referenceNumber" class="input" id="Reference-id" placeholder="Please Enter a Reference ID">
                    <button class="btn-enter" id="dashboardButton" tooltip="You must enter a valid Reference ID before proceeding" type="submit" (click)="Next()" [disabled]="referenceForm.invalid">Enter</button>
                    <button class="btn-clear" id="dashboardButton" type="button" (click)="clear()" [disabled]="referenceForm.invalid">Clear</button>
            </form>
        </div>
    </div>
    <div class="col-md-12" *ngIf="error">You must enter a valid Reference ID before proceeding</div>
</div>

<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1 dashboardfooter">       
    <span>If you have questions regarding the letter you received requesting information, please locate the contact information contained in that letter and reach out to the appropriate party. </span> <br>         
    <span>For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email</span>
    <div class="row">
      <div class="text-white-s ml-5">
          <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> | 
          <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
          <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
      </div>
    </div>
  </div>
  