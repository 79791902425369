import { Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileServiceService } from '../service/file-service.service';
import { FileCaptureService } from 'app/service/file-capture.service';
import { takeWhile } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-email-auth',
  templateUrl: './email-auth.component.html',
  styleUrls: ['./email-auth.component.css']
})
export class EmailAuthComponent implements OnInit {
  
  timerMinutes: number = 2;
  timerSeconds: number = 0;
  timerSubscription: Subscription;
  authCode = '';
  emailAuthKey = '';
  isValidCode : boolean;
  emailForm: FormGroup = new FormGroup({
    validateEmail: new FormControl(''),
    emailCode: new FormControl('', [Validators.required, Validators.maxLength(6), Validators.pattern(/^\d{6}$/)]),
    contactEmail: new FormControl('', [Validators.required, Validators.maxLength(6), Validators.email])
  });
  disablesumbit: boolean = false;
  isVisible: boolean;
  invalid: boolean;
  captcha: string;
  showCaptcha: boolean | undefined;  
  isValidCodeProgress: boolean = true;
  
  constructor(private route: Router, public service: FileServiceService, public fileService: FileCaptureService) {
    this.service.pageIndex = 2;
   }
   ngOnInit() {
    this.startTimer();
    this.emailForm.get('validateEmail')?.setValue(this.service.getCache("contactEmail"));  
    this.emailForm.get('emailCode')?.setValue('');
    if (this.emailForm.get('emailCode')?.value === '' || this.emailForm.get('emailCode')?.value === null) {
      // this.emailForm.get('submit')?.disable();
      this.disablesumbit = true;
     }
    // Subscribe to the valueChanges event on the email code input field
    this.emailForm.get('emailCode')?.valueChanges.subscribe((value) => {
      if (value === '') {
        this.disablesumbit = true;
      } else {
        this.disablesumbit = false;
      }
    });
  }

  next() {
  
    this.route.navigate(['file-upload']);
  }
  close() {
    this.service.referenceFileDetails.referenceId = "";
    this.service.clearCache();
    this.route.navigate(['']);
  }
  enableSubmit(){
    this.isValidCodeProgress = false;
  }
  validateCode() {
    this.invalid = false;
    this.isValidCodeProgress = true
    this.isVisible = true;
    var data = {
      emailAuthKey: this.emailForm.get('emailAuthKey')?.value,
      authCode : this.emailForm.get('emailCode')?.value,
      email : this.service.getCache("contactEmail")
    }
    this.fileService.validateCode(data).subscribe(isValidCode => {
      this.isValidCodeProgress = false;
      if(isValidCode){
        this.invalid = false;
        this.next();
      }else{
        this.invalid = true;
      }
      this.isVisible = false;
      
    });
  }
  startTimer() {
    // Create an interval that updates the timer every second
    this.timerSubscription = interval(1000).pipe(
      takeWhile(() => this.timerMinutes >= 0 && this.timerSeconds >= 0)
    ).subscribe(() => {
      if (this.timerSeconds === 0) {
        if (this.timerMinutes > 0) {
          this.timerMinutes--;
          this.timerSeconds = 59;
        } else {
          // Timer reached 0, we need to handle this as needed in future
          this.timerSubscription.unsubscribe(); // Stop the timer
        }
      } else {
        this.timerSeconds--;
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe from the timer when the component is destroyed
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resetTimer() {
    this.timerMinutes = 2;
    this.timerSeconds = 0;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe(); // Stop the previous timer
    }
    const contactEmail = this.service.getCache("contactEmail");
    this.fileService.generateCode(contactEmail, this.service.referenceFileDetails.referenceId).subscribe(authCode => {
      this.authCode = authCode;
      this.startTimer();
    });
     // Start a new timer
  }

  captchaEvent(captcha: string) {
    this.captcha = captcha;
  }
}