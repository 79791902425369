import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileServiceService } from '../service/file-service.service';
import { FileCaptureService } from '../service/file-capture.service';
import { NgxSpinnerService } from 'ngx-spinner'
import { ProgressService } from 'app/service/progress.service';
import { AuthService } from 'app/service/auth-service';
import { map } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  error: boolean = false;
  progressMessage: string;
  referenceForm: FormGroup = new FormGroup({
    referenceNumber: new FormControl('', [Validators.required, Validators.maxLength(60)])
  });

  constructor(private route: Router, private service: FileServiceService,
    private fcservice : FileCaptureService, private ngxsippner : NgxSpinnerService, private authService: AuthService) {
    this.service.pageIndex = 0;
    this.authService.setIsLoggedIn(false);
   }

  ngOnInit() {
    this.referenceForm.get('referenceNumber')?.setValue(this.service.referenceFileDetails.referenceId);    
  }


  clear() {
    this.referenceForm.get('referenceNumber')?.setValue('');
    this.error = false;
  }

  Next(){
    this.ngxsippner.show()
    this.service.referenceFileDetails.referenceId = this.referenceForm.get('referenceNumber')?.value;
    this.service.setCacheForitems(
      [{id:"referenceId", value:this.service.referenceFileDetails.referenceId}]
  );
    if(this.referenceForm.get('referenceNumber')){
      this.fcservice.getReferenceId(this.referenceForm.get('referenceNumber')?.value).subscribe((res) => {
        this.ngxsippner.hide()
        if(res){
          this.route.navigate(['reference']); 
        }else{
          this.ngxsippner.hide();
          this.error = true;
        }
      }, error => {this.ngxsippner.hide()});
    }
  }

}
