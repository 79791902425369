<app-header></app-header>

<div class="col-md-3 offset-md-1 ">
  <div class="row">
    <span class="text-white ref">Reference ID</span>
  </div>
  <div class="row">
    <span class="ref-id-no">{{ service.referenceFileDetails.referenceId }}</span>
  </div>
</div>

<form (ngSubmit)="next()"  class="referenceheader">
  <div class="row link-container">
    <div class="col-md-auto offset-md-1 mt-3">
      <a class="btn btn-info custom-button" *ngIf="service.pageIndex>0" (click)="service.navigateTo('reference')">Contact Information</a>
    </div>
    <div class="col-md-auto mt-3">
      <a class="btn btn-info custom-button" *ngIf="service.pageIndex>2" (click)="service.navigateTo('file-upload')">File Upload</a>
    </div>
    <div class="col-md-auto mt-3">
      <a class="btn btn-info custom-button" *ngIf="service.pageIndex>3" (click)="service.navigateTo('review-files')">Review Files</a>
    </div>
    <div class="col-md-auto mt-3">
      <a class="btn btn-info custom-button" *ngIf="service.pageIndex>4" (click)="service.navigateTo('initiate-download')">Initiate Download</a>
    </div>
  </div>
  <div class="row mt-5 mb-3 offset-md-1">
    <div class="text-white">
      <h4>Please Confirm the number of Files Uploaded is correct before Submission!</h4>
    </div>
  </div>
  <div class="row offset-md-1">
    <div class="text-white col mb-5">
      <span class="border-1">{{service.referenceFileDetails.files.length}} Files have been Uploaded</span>
    </div>
  </div>
    <div class="col mb-3 offset-md-1 mb-4">
      <span class="text-white">If you are Finished and have verified the number of files is correct, click Submit Documents. Once
        files have been successfully uploaded, you will receive a Confirmation email.</span> <br>
        <span class="text-white">If a problem occurs during the file transfer, you will be notified and asked to re-submit any files that failed.</span>
    </div>
    <div class="text-white mb-4 offset-md-1">
      <span>You cannot click Back once you have selected Submit. This is the final step.</span>
    </div>
    <div class="g-recaptcha" data-sitekey="your_site_key"></div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
      <p style="color: white">Please do not Cancel or Refresh page</p>
    </ngx-spinner>
    <div class="button-container offset-md-1">
      <button type="button" class="btn-clear" id="dashboardButton" (click)="back()">Back</button>
      <button type="submit" class="btn-enter" id="dashboardButton">Submit Documents</button>
  </div>
  
</form>
<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1">   
  <span>If you have questions regarding the letter you received requesting information, please locate the contact information contained in that letter and reach out to the appropriate party. </span> <br>             
  <span>For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email</span>
<div class="row">
  <div class="text-white-s ml-5">
      <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> | 
      <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
      <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
  </div>
</div>
</div>