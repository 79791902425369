import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class AuthService {    
    private isloggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoggedIn$: Observable<boolean>;
  constructor() {
    this.isLoggedIn$ = this.isloggedIn.asObservable();
  }
  getIsloggedIn(): BehaviorSubject<boolean> {
    return this.isloggedIn;
  }
  setIsLoggedIn(value: boolean) {
    this.isloggedIn.next(value);
  }
  }