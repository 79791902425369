<div class="col-md-3 offset-md-1 mt-5">
    <div class="row">
        <span class="text-white">Reference ID</span>
    </div>
    <div class="row">
        <span class="ref-id-no">DEMO5202273055</span>
    </div>
</div>

<div class="row">
    <div class="col-md-4 offset-md-1 ml-5 mt-5">
        <button class="btn btn-info w-40">Contact Information</button>
    </div>
</div>

<div class="row">
    <div class="col-md-8 offset-md-1 mt-4 mb-5">
        <span class="file-download-err">Please provide contact information for any questions or concerns with the file download.</span>
    </div>
</div>

<form (ngSubmit)="next()">
    <div class="col-md-3 offset-md-1">
        <div class="form-group mb-2">
            <label class="text-white">Business Name</label>
            <input type="text" class="form-control" id="business" placeholder="Enter Business Name">
        </div>
        <div class="form-group mb-2">
            <label class="text-white">Contact Name</label>
            <input type="text" class="form-control" id="contact-name">
        </div>
        <div class="form-group mb-2">
            <label class="text-white">Contact Phone</label>
            <input type="text" class="form-control" id="contact-phone">
        </div>
        <div class="form-group mb-2">
            <label class="text-white">Contact Email</label>
            <input type="text" class="form-control" id="contact-email">
        </div> 
    </div>

    <div class="row offset-md-1 mt-3">
        <div class="col-md-1">
            <button type="button" class="btn btn-warn">Close</button>
        </div>
        <div class="col">
            <button type="submit" class="btn btn-success">Click to Begin File Upload</button>
        </div>
    </div>
</form>
