import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { InitiateDownloadComponent } from './initiate-download/initiate-download.component';
import { ReferanceComponent } from './referance/referance.component';
import { ReviewFilesComponent } from './review-files/review-files.component';
import { TabsComponent } from './tabs/tabs.component';
import { LandingPageGuard} from './guards/landingPageGuard';
import { EmailAuthComponent} from './email-auth/email-auth.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'reference',
    component: ReferanceComponent,
    canActivate: [LandingPageGuard]
  },
  {
    path: 'file-upload',
    component: FileUploadComponent,
    canActivate: [LandingPageGuard]
  },
  {
    path: 'review-files',
    component: ReviewFilesComponent
  },
  {
    path: 'initiate-download',
    component: InitiateDownloadComponent
  },
  {
    path: 'confirmatiion',
    component: ConfirmationComponent
  },
  {
    path: 'email-auth',
    component: EmailAuthComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
