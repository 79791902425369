import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-re-captcha',
  templateUrl: './re-captcha.component.html',
  styleUrls: ['./re-captcha.component.css']
})
export class ReCaptchaComponent implements OnInit {
  sitekey: string;
  @Output() captchaEvent = new EventEmitter<any>();
  captcha: string;// empty = not yet proven to be a human, anything else = human

  constructor() { 
    this.captcha = '';
    this.sitekey = environment.capchaSiteKey;
  }

  ngOnInit(): void {
  }
  
  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    this.captchaEvent.emit(this.captcha);
    console.log('resolved captcha with response: ' + this.captcha);
}
}
