import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/service/auth-service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageGuard implements CanActivate {


  constructor(private authService: AuthService) {
   }

  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Return false to prevent the user from navigating to the landing page
    this.authService.setIsLoggedIn(!(state.url === '/'));
    return !(state.url === '/');
  }
}