import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export interface fileDetails {
  referenceId: string;
  businessName: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  files: File[];
  submittedDate: Date | undefined;
  caseActivityKey: string;
  validateEmail: string;
  emailCode: BigInteger;
}

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  referenceFileDetails: fileDetails;
  pageIndex: number = 0;

  constructor(private route: Router) { 
    this.referenceFileDetails = {
      referenceId: this.getCache("referenceId"),
      businessName: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      files: [],
      submittedDate: undefined,
      caseActivityKey:'',
      validateEmail:'',
      emailCode: undefined

    };
    this.pageIndex = 0;
  }

  setCacheForitems(items : any)
  {
    items.forEach(element => {
      sessionStorage.setItem(element.id, JSON.stringify(element.value));
    });
    
  }
  getCache(id : string)
  {
    return JSON.parse(sessionStorage.getItem(id));
    
  }
  clearCache()
  {
    sessionStorage.clear();
  }

  reset() {
    this.referenceFileDetails = {
      referenceId: '',
      businessName: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      files: [],
      submittedDate: undefined,
      caseActivityKey: '',
      validateEmail:'',
      emailCode: undefined
    };
    this.pageIndex = 0;
  }

  navigateTo(path: string) {
    this.route.navigate([path]);
  }
}
