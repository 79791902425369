<div class="row justify-content-start align-items-center g-2">
    <div class="col col-md-2 offset-1">
        <img src="../../assets/images/logo.png" alt=""  style="width:200px; margin-left:1px;margin-top:18px;">
    </div>
</div>

<div class="row">   
    <div class="col col-md-4 offset-1 mb-3">
        <h1 class="main-heading-font">
            <span style="color: #00F5B1;">File</span>
            <span style="color: white;">Capture</span>
        </h1>
    </div>
</div>
